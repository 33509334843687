import {advertisingList, advertisingInfo, operationsStuEaxmOperations, operationsStuOperations, operationsGetTrain} from '@/utils/apis'

export default {
    name: "StepOne",
    data() {
        return {
            //步骤
            step_active: this.$route.query.step || '',
            //媒体类型
            mediaType: 1,
            //推广计划类型
            promotionPlanType: 1,
            //出价方式-京东定向推广计划
            biddingType: 1,
            //投放日期-有两个选项
            deliveryTime2: 1,
            deliveryTimeValue: '',
            //每日投放时间
            deliveryTimeType: 1,
            //特定时间投放列表
            timeList: [
                {
                    day: '星期一',
                    val: 'day1',
                    data: [
                        {
                            val: 'day1',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期二',
                    val: 'day2',
                    data: [
                        {
                            val: 'day2',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期三',
                    val: 'day3',
                    data: [
                        {
                            val: 'day3',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期四',
                    val: 'day4',
                    data: [
                        {
                            val: 'day4',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期五',
                    val: 'day5',
                    data: [
                        {
                            val: 'day5',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期六',
                    val: 'day6',
                    data: [
                        {
                            val: 'day6',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期日',
                    val: 'day7',
                    data: [
                        {
                            val: 'day7',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 23,
                            is_click: false,
                        }
                    ]
                }
            ],
            //选择的特定投放时间段
            put_time_obj: {
                day1: [],
                day2: [],
                day3: [],
                day4: [],
                day5: [],
                day6: [],
                day7: []
            },
            //每日限额
            dailyLimitType: 1,
            //设置每日限额
            quota: void 0,
            //高级规则
            advancedRules: 1,
            //推广计划名称
            promotionPlanName: '',
            //投放位置下拉列表
            placementLists: [],
            placementValue: '',
            //投放位置模块列表
            placementTableList: [],
            //全部投放位置列表
            allPlacementTableList: [],
            templateSelection: {},
            flagValue: null,
            //定向名称
            orientationTitle: '',
            //选择区域
            //区域列表
            areaLists: ["A  安徽", "H  河南", "N  内蒙古", "X  新疆", "B  北京", "H  海南", "N  宁夏", "X  西藏", "C  重庆", "H  湖北", "Q 青海", "Y  云南", "F  福建", "H  湖南", "S  上海", "Z  浙江", "G  广东", "H  黑龙江", "S  四川", "G  广西", "J  吉林", "S  山东", "G  甘肃", "J  江苏", "S  山西", "G  贵州", "J  江西", "S  陕西", "H  河北", "L  辽宁", "T  天津"],
            checkAreaList: [],
            checkAll: false,
            isIndeterminate: true,
            //点击出价
            clickToBid: void 0,
            //广告名称
            adTitle: '',
            //广告备注
            adRemarks: '',
            //第一步数据
            JD_step_one: {},
            //分页
            placementTablePages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
            //落地页类型
            creativityMediaType: 1,
            //推广链接
            sponsoredLinks: '',
            //主推SKUID
            sponsoredSKUID: '',
            //素材标题
            materialTitle: '',
            //上传图片
            material: '',
            //请求头
            headerParam: {
                Authorization: localStorage.getItem('studentToken') || ''
            },
            //提交的参数
            param: {}
        }
    },
    created() {
        if (this.step_active === '2') {
            this.getPlacementLists();
        }
        if (this.$route.query.editId) {
            this.getOperationsGetTrain();
        }
    },
    watch: {
        $route(to) {
            if (to.query.step === '1') {
                this.step_active = '1';
                //媒体类型
                if (JSON.parse(localStorage.getItem('param')).op_plan.media_type) {
                    this.mediaType = JSON.parse(localStorage.getItem('param')).op_plan.media_type;
                }
                //推广计划类型
                if (JSON.parse(localStorage.getItem('param')).op_plan.promote_type) {
                    this.promotionPlanType = JSON.parse(localStorage.getItem('param')).op_plan.promote_type;
                }
                //出价方式
                if (JSON.parse(localStorage.getItem('param')).op_plan.offer_type) {
                    this.biddingType = JSON.parse(localStorage.getItem('param')).op_plan.offer_type;
                }
                //投放日期
                if (JSON.parse(localStorage.getItem('param')).op_plan.put_date) {
                    if (JSON.parse(localStorage.getItem('param')).op_plan.put_date === 'all') {
                        this.deliveryTime2 = 1;
                    } else {
                        this.deliveryTime2 = 2;
                        this.deliveryTimeValue = JSON.parse(localStorage.getItem('param')).op_plan.put_date.split('#');
                    }
                }
                //每日投放时间
                if (JSON.parse(localStorage.getItem('param')).op_plan.put_time) {
                    if (JSON.parse(localStorage.getItem('param')).op_plan.put_time === 'all') {
                        this.deliveryTimeType = 1;
                    } else {
                        this.deliveryTimeType = 2;
                        if ((JSON.parse(localStorage.getItem('param'))).op_plan.put_time) {
                            let arr = JSON.parse((JSON.parse(localStorage.getItem('param'))).op_plan.put_time);
                            let day1 = this.timeList[0];
                            let day2 = this.timeList[1];
                            let day3 = this.timeList[2];
                            let day4 = this.timeList[3];
                            let day5 = this.timeList[4];
                            let day6 = this.timeList[5];
                            let day7 = this.timeList[6];
                            arr.day1.forEach(select_item => {
                                day1.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day2.forEach(select_item => {
                                day2.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day3.forEach(select_item => {
                                day3.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day4.forEach(select_item => {
                                day4.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day5.forEach(select_item => {
                                day5.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day6.forEach(select_item => {
                                day6.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day7.forEach(select_item => {
                                day7.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                        }
                    }
                }

                //每日限额
                if (JSON.parse(localStorage.getItem('param')).op_plan.limit) {
                    if (JSON.parse(localStorage.getItem('param')).op_plan.limit === '0') {
                        this.dailyLimitType = 1;
                    } else {
                        this.dailyLimitType = 2;
                        this.quota = JSON.parse(localStorage.getItem('param')).op_plan.limit;
                    }
                }
                //高级规则
                if (JSON.parse(localStorage.getItem('param')).op_plan.advanced_rules) {
                    this.advancedRules = JSON.parse(localStorage.getItem('param')).op_plan.advanced_rules;
                }
                //推广计划名称
                this.promotionPlanName = JSON.parse(localStorage.getItem('param')).op_plan.plan_name;
            }
            if (to.query.step === '2') {
                this.step_active = '2';
                this.getPlacementLists();
                //定向名称
                // this.orientationTitle = JSON.parse(localStorage.getItem('param')).op_directional.directional_name;
            }
            if (to.query.step === '3') {
                this.step_active = '3';
            }
        }
    },
    methods: {
    
        //获取详情
        getOperationsGetTrain() {
            let param = {
                op_id: this.$route.query.op_id
            }
            if (this.$route.query.course_id) {
                param.course_id = this.$route.query.course_id;
                param.type = 1;
            } else {
                param.eaxm_id = this.$route.query.eaxm_id;
                param.type = 2;
            }
            operationsGetTrain(param).then(res => {
                //媒体类型
                this.mediaType = res.data.plan.media_type;
                //推广计划类型
                this.promotionPlanType = res.data.plan.promote_type;
                //出价方式
                if (res.data.plan.offer_type) {
                    this.biddingType = res.data.plan.offer_type;
                }
                //投放日期
                if (res.data.plan.put_date) {
                    if (res.data.plan.put_date === 'all') {
                        this.deliveryTime2 = 1;
                    } else {
                        this.deliveryTime2 = 2;
                        this.deliveryTimeValue = res.data.plan.put_date.split('#');
                    }
                }
                //每日投放时间
                if (res.data.plan.put_time) {
                    if (res.data.plan.put_time === 'all') {
                        this.deliveryTimeType = 1;
                    } else {
                        this.deliveryTimeType = 2;
                        if (res.data.plan.put_time) {
                            let arr = JSON.parse(res.data.plan.put_time);
                            let day1 = this.timeList[0];
                            let day2 = this.timeList[1];
                            let day3 = this.timeList[2];
                            let day4 = this.timeList[3];
                            let day5 = this.timeList[4];
                            let day6 = this.timeList[5];
                            let day7 = this.timeList[6];
                            arr.day1.forEach(select_item => {
                                day1.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day2.forEach(select_item => {
                                day2.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day3.forEach(select_item => {
                                day3.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day4.forEach(select_item => {
                                day4.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day5.forEach(select_item => {
                                day5.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day6.forEach(select_item => {
                                day6.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                            arr.day7.forEach(select_item => {
                                day7.data.forEach(item => {
                                    if (select_item === item.num) {
                                        item.is_click = true;
                                    }
                                })
                            });
                        }
                    }
                }
                //每日限额
                if (res.data.plan.limit === 0) {
                    this.dailyLimitType = 1;
                }  else {
                    this.dailyLimitType = 2;
                    this.quota = res.data.plan.limit;
                }
                //高级规则
                if (res.data.plan.advanced_rules) {
                    this.advancedRules = res.data.plan.advanced_rules;
                }
                //推广计划名称
                this.promotionPlanName = res.data.plan.plan_name;
                //投放位置
                if (res.data.directional.advertising_id) {
                    this.placementValue = res.data.directional.advertising_id;
                    this.getPlacementDetails(this.placementValue);
                    this.templateSelection = JSON.parse(res.data.directional.put_position);
                    if (this.mediaType === 1) {
                        this.flagValue = JSON.parse(res.data.directional.put_position).id;
                    }
                    if (this.mediaType === 2) {
                        this.flagValue = (JSON.parse(res.data.directional.put_position).id) - 15;
                    }
                    if (this.mediaType === 3) {
                        this.flagValue = (JSON.parse(res.data.directional.put_position).id) - 46;
                    }
                    if (this.mediaType === 4) {
                        this.flagValue = (JSON.parse(res.data.directional.put_position).id) - 61;
                    }
                }
                //定向名称
                this.orientationTitle = res.data.directional.directional_name;
                //区域
                if (res.data.directional.area === 'all') {
                    this.checkAll = true;
                    this.isIndeterminate = false;
                    this.checkAreaList = this.areaLists;
                } else {
                    this.checkAreaList = res.data.directional.area.split(',');
                }
                //点击出价
                this.clickToBid = res.data.directional.click_unit_price;
                //广告名称
                this.adTitle = res.data.directional.advertising_name;
                //广告备注
                if (res.data.directional.mark) {
                    this.adRemarks = res.data.directional.mark;
                }
                //创意-媒体类型
                this.creativityMediaType = res.data.creative.media_type;
                //推广链接
                this.sponsoredLinks = res.data.creative.promote_link;
                //主推SKUID
                this.sponsoredSKUID = res.data.creative.sku_id;
                //素材标题
                this.materialTitle = res.data.creative.material_title;
                //上传素材
                this.material = res.data.creative.material;
            }).catch(err => {
                console.log('err', err);
            })
        },
        //选择媒体类型
        selectMediaType(val) {
            this.mediaType = val;
            this.promotionPlanType = 1;
            this.biddingType = 1;
            this.deliveryTime2 = 1;
            this.deliveryTimeValue = '';
            this.deliveryTimeType = 1;
            this.dailyLimitType = 1;
            this.quota = void 0;
            this.advancedRules = 1;
            this.flagValue = null;
            this.placementTableList = [];
            this.timeList.forEach(item => {
                item.data.forEach(dataItem => {
                    dataItem.is_click = false;
                })})
            this.filterPlacement();
        },
        //选择推广计划类型
        selectPromotionPlan(val) {
            this.promotionPlanType = val;
            this.biddingType = 1;
            this.deliveryTime2 = 1;
            this.deliveryTimeValue = '';
            this.deliveryTimeType = 1;
            this.dailyLimitType = 1;
            this.quota = void 0;
            this.advancedRules = 1;
            this.timeList.forEach(item => {
                item.data.forEach(dataItem => {
                    dataItem.is_click = false;
                })})
        },
        //获取投放位置列表
        getPlacementLists() {
            let param = {
                status: "0"
            }
            advertisingList(param).then(res => {
                console.log('333')
                this.placementLists = res.data;
            }).catch(err => {
                console.log(err);
            })
        },
        //选中的投放位置名称
        selectPlacement(val) {
            this.getPlacementDetails(val);
        },
        //获取投放位置详情
        getPlacementDetails(id) {
            let param = {
                id: id
            }
            advertisingInfo(param).then(res => {
                this.placementTableList = [];
                this.allPlacementTableList = JSON.parse(res.data.advertising_data);
                this.filterPlacement();
            }).catch(err => {
                console.log(err);
            })
        },
        //筛选投放位置列表
        filterPlacement() {
            this.allPlacementTableList.forEach(item => {
                if (this.mediaType === item.type) {
                    this.placementTableList.push(item);
                }
            })
        },
        //选择投放位置
        getTemplateRow(index,row){
            //获取选中数据
            this.templateSelection = row;
        },
        //全选
        handleCheckAllChange(val) {
            this.checkAreaList = val ? this.areaLists : [];
            this.isIndeterminate = false;
        },
        //选择区域
        handleCheckedAreaChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.areaLists.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.areaLists.length;
        },
        //分页
        placementTableCurrentChange(val) {
            this.placementTablePages.currentPageNum = val;
        },
        //上传图片
        handleCoverSuccess(res, file) {
            if (res.code === 200) {
                this.material = res.data; //无域名
                this.$message.success('上传成功')
            } else {
                this.$message.error('上传失败，请稍后再试~')
            }
        },
        beforeCoverUpload(file) {
            const isSize = new Promise((resolve, reject) => {
                const width = 1080;
                const height = 1920;
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                img.onload = () => {
                    const valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file);
            }).then(() => {
                    return file;
                }, () => {
                    this.$message.warning('图片尺寸限制为1080px x 1920px');
                    return Promise.reject();
                },
            );
            return isSize;
        },
        //上一步
        previous() {
            if (this.step_active === '2') {
                if (this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            step: 1,
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: this.$route.query.editId
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            step: 1,
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id
                        }
                    });
                }

            }
            if (this.step_active === '3') {
                if (this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            step: 2,
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: this.$route.query.editId
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            step: 2,
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id
                        }
                    });
                }
            }

        },
        //保存并继续
        goToTwo() {
            this.param = {
                op_id: this.$route.query.op_id,
                op_plan: {
                    media_type: this.mediaType,//媒体类型
                    promote_type: this.promotionPlanType,//推广计划类型
                },
                op_directional: {
                    put_position: ''
                },
                op_creative: {
                    media_type: this.creativityMediaType,//落地页类型
                }
            }
            if (this.$route.query.course_id) {
                this.param.course_id = this.$route.query.course_id;
            } else {
                this.param.eaxm_id = this.$route.query.exam_id;
            }
            //第一步
            if (!(this.mediaType === 1 && this.promotionPlanType === 3)) {
                //出价方式
                this.param.op_plan.offer_type = this.biddingType;
                //投放日期
                if ((this.mediaType === 1 && this.promotionPlanType === 1) ||
                    (this.mediaType === 2 && this.promotionPlanType === 1) ||
                    (this.mediaType === 2 && this.promotionPlanType === 2) ||
                    (this.mediaType === 3 && this.promotionPlanType === 1) ||
                    (this.mediaType === 3 && this.promotionPlanType === 2) ||
                    (this.mediaType === 3 && this.promotionPlanType === 3) ||
                    (this.mediaType === 4 && this.promotionPlanType === 1) ||
                    (this.mediaType === 4 && this.promotionPlanType === 2)) {
                    if (this.deliveryTime2 === 1) {
                        this.param.op_plan.put_date = 'all';
                    } else {
                        if (!this.deliveryTimeValue) {
                            return this.$message.warning('请填写投放日期！');
                        } else {
                            this.param.op_plan.put_date = `${this.deliveryTimeValue[0]}#${this.deliveryTimeValue[1]}`;
                        }
                    }
                }
                //每日投放时间
                if ((this.mediaType === 1 && this.promotionPlanType === 1) ||
                    (this.mediaType === 2 && this.promotionPlanType === 1) ||
                    (this.mediaType === 2 && this.promotionPlanType === 2) ||
                    (this.mediaType === 3 && this.promotionPlanType === 1) ||
                    (this.mediaType === 3 && this.promotionPlanType === 2) ||
                    (this.mediaType === 3 && this.promotionPlanType === 3) ||
                    (this.mediaType === 4 && this.promotionPlanType === 1) ||
                    (this.mediaType === 4 && this.promotionPlanType === 2)) {
                    if (this.deliveryTimeType === 1) {
                        this.param.op_plan.put_time = 'all';
                    } else {
                        this.put_time_obj = {
                            day1: [],
                            day2: [],
                            day3: [],
                            day4: [],
                            day5: [],
                            day6: [],
                            day7: []
                        },
                            this.timeList.forEach(item => {
                                item.data.forEach(dataItem => {
                                    if (dataItem.is_click === true) {
                                        if (dataItem.val === 'day1') {
                                            this.put_time_obj.day1.push(dataItem.num);
                                        }
                                        if (dataItem.val === 'day2') {
                                            this.put_time_obj.day2.push(dataItem.num);
                                        }
                                        if (dataItem.val === 'day3') {
                                            this.put_time_obj.day3.push(dataItem.num);
                                        }
                                        if (dataItem.val === 'day4') {
                                            this.put_time_obj.day4.push(dataItem.num);
                                        }
                                        if (dataItem.val === 'day5') {
                                            this.put_time_obj.day5.push(dataItem.num);
                                        }
                                        if (dataItem.val === 'day6') {
                                            this.put_time_obj.day6.push(dataItem.num);
                                        }
                                        if (dataItem.val === 'day7') {
                                            this.put_time_obj.day7.push(dataItem.num);
                                        }
                                    }
                                })
                            })
                        if (this.put_time_obj.day1.length === 0 &&
                            this.put_time_obj.day2.length === 0 &&
                            this.put_time_obj.day3.length === 0 &&
                            this.put_time_obj.day4.length === 0 &&
                            this.put_time_obj.day5.length === 0 &&
                            this.put_time_obj.day6.length === 0 &&
                            this.put_time_obj.day7.length === 0) {
                            this.$message.warning('请至少选择一个时间段！');
                            return;
                        } else {
                            this.param.op_plan.put_time = JSON.stringify(this.put_time_obj);
                        }
                    }
                }
                //每日限额
                if (this.dailyLimitType === 1) {
                    this.param.op_plan.limit = 0;
                } else {
                    if (this.quota) {
                        this.param.op_plan.limit = this.quota;
                    } else {
                        this.$message.warning('请设置每日限额！');
                        return;
                    }
                }
                //高级规则
                if ((this.mediaType === 1 && this.promotionPlanType === 2) ||
                    (this.mediaType === 1 && this.promotionPlanType === 4) ||
                    (this.mediaType === 2 && this.promotionPlanType === 2) ||
                    (this.mediaType === 4 && this.promotionPlanType === 2)) {
                    this.param.op_plan.advanced_rules = this.advancedRules;
                }
            }
            if (!this.promotionPlanName) {
                this.$message.warning('请输入推广计划名称！');
                return;
            }
            this.param.op_plan.plan_name = this.promotionPlanName;
            if (this.$route.query.editId) {
                this.$router.push({
                    path: '/student/JDDirectInvestment/stepOne',
                    query: {
                        step: 2,
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                        editId: this.$route.query.editId
                    }
                });
            } else {
                this.$router.push({
                    path: '/student/JDDirectInvestment/stepOne',
                    query: {
                        step: 2,
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id
                    }
                });
            }

            localStorage.removeItem('param');
            localStorage.setItem('param', JSON.stringify(this.param));
        },
        goToThree() {
            //第二步
            //投放位置
            if (this.templateSelection.click_rate) {
                this.param.op_directional.put_position = JSON.stringify(this.templateSelection);
                this.param.op_directional.advertising_id = this.placementValue;
            } else {
                this.$message.warning('请选择投放位置！');
                return
            }
            if (!this.flagValue) {
                this.$message.warning('请选择投放位置！');
                return
            }
            //定向名称
            if (!this.orientationTitle) {
                this.$message.warning('请选择定向名称！');
                return
            } else {
                this.param.op_directional.directional_name = this.orientationTitle;
            }
            //选择区域
            if (this.checkAreaList.length === 0) {
                this.$message.warning('请至少选择一个区域！');
                return;
            }
            if (this.checkAreaList.length === 31) {
                this.param.op_directional.area = 'all';
            } else {
                this.param.op_directional.area = this.checkAreaList.join(',');
            }
            //点击出价
            if (!this.clickToBid) {
                this.$message.warning('请填写点击出价！');
                return;
            } else {
                this.param.op_directional.click_unit_price = this.clickToBid;
            }
            //广告名称
            if (!this.adTitle) {
                this.$message.warning('请填写广告名称！');
                return;
            } else {
                this.param.op_directional.advertising_name = this.adTitle;
            }
            //广告备注
            if (this.adRemarks) {
                this.param.op_directional.mark = this.adRemarks;
            }
            localStorage.removeItem('param');
            localStorage.setItem('param', JSON.stringify(this.param));
            if (this.$route.query.step === '2') {
                if (this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            step: 3,
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: this.$route.query.editId
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            step: 3,
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id
                        }
                    });
                }
            }
        },
        finishPut() {
          
            //第三步
            if (!this.sponsoredLinks) {
             
                this.$message.warning('请填写推广链接！');
                return;
            } else {
                let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                let re = new RegExp(strRegex)
                if (!re.test(this.sponsoredLinks)) {
                    this.$message.warning('首页链接不是以http://https://开头，或者不是网址！');
                    return;
                } else {
                    // console.log(this.param,'****-****')
                      this.param.op_creative.promote_link = this.sponsoredLinks;
                }
            }
            if (!this.sponsoredSKUID) {
                this.$message.warning('请填写主推SKUID！');
                return;
            } else {
                this.param.op_creative.sku_id = this.sponsoredSKUID;
            }
            if (!this.materialTitle) {
                this.$message.warning('请填写素材标题！');
                return;
            } else {
                this.param.op_creative.material_title = this.materialTitle;
                this.param.op_creative.material = this.material;
            }
            if (!this.material) {
                this.$message.warning('请上传素材！');
                return;
            } else {
                this.param.op_creative.material = this.material;
            }
            if (this.$route.query.editId) {
                this.param.id = this.$route.query.editId;
            }
            this.param.op_creative.media_type = this.creativityMediaType
            localStorage.removeItem('param');
            localStorage.setItem('param', JSON.stringify(this.param));
            if (this.$route.query.course_id) {
                operationsStuOperations(this.param).then(res => {
                    this.$message.success(res.msg);
                    this.$router.push({
                        path: '/student/JDDirectInvestment/list',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id
                        }
                    })
                    localStorage.removeItem('param');
                }).catch(err => {
                    console.log(err);
                })
            } else {
                operationsStuEaxmOperations(this.param).then(res => {
                    this.$message.success(res.msg);
                    this.$router.push({
                        path: '/student/JDDirectInvestment/list',
                        query: {
                            op_id: this.$route.query.op_id,
                            eaxm_id: this.$route.query.eaxm_id
                        }
                    })
                    localStorage.removeItem('param');
                }).catch(err => {
                    console.log(err);
                })
            }
        },
        //取消
        cancelList() {
            this.$router.push({
                path: '/student/JDDirectInvestment/list',
                query: {
                    op_id: this.$route.query.op_id,
                    course_id: this.$route.query.course_id
                }
            });
        }
    }
}